/* Overrides user components-variables */
@import "~bootstrap/scss/functions";

@import "../variables/variables";
@import "~bootstrap/scss/variables";
$white-color: (
  "base": #ffffff
);
$black-color: (
  "base": #000000
);
$primary-color: (
  "lighten-4": #9ab9f8,
  "lighten-3": #89adf7,
  "lighten-2": #79a1f5,
  "lighten-1": #6896f4,
  "base": #578af3,
  "darken-1": #4e7cdb,
  "darken-2": #466ec2,
  "darken-3": #3d61aa,
  "darken-4": #345392
);
$secondary-color: (
  "lighten-4": #fbd17c,
  "lighten-3": #fbc966,
  "lighten-2": #fac150,
  "lighten-1": #faba3a,
  "base": #f9b224,
  "darken-1": #e0a020,
  "darken-2": #c78e1d,
  "darken-3": #ae7d19,
  "darken-4": #956b16
);
$success-color: (
  "lighten-4": #2ab173,
  "lighten-3": #2fc580,
  "lighten-2": #3cd18d,
  "lighten-1": #51d699,
  "base": #51d699,
  "darken-1": #66dba5,
  "darken-2": #7ae0b1,
  "darken-3": #8fe5bd,
  "darken-4": #a4e9c9
);
$danger-color: (
  "lighten-4": #f9a2a2,
  "lighten-3": #f89393,
  "lighten-2": #f78383,
  "lighten-1": #f67474,
  "base": #f56464,
  "darken-1": #dd5a5a,
  "darken-2": #c45050,
  "darken-3": #ac4646,
  "darken-4": #933c3c
);
$warning-color: (
  "lighten-4": #FEEFD0,
  "lighten-3": #FEDBA1,
  "lighten-2": #FCC173,
  "lighten-1": #FAA750,
  "base": #F77E17,
  "darken-1": #D46010,
  "darken-2": #B1460B,
  "darken-3": #8F2F07,
  "darken-4": #762004
);
$info-color: (
  "lighten-4": #D5F1FD,
  "lighten-3": #ACE0FC,
  "lighten-2": #81C8F7,
  "lighten-1": #60AFF0,
  "base": #2F8BE6,
  "darken-1": #226CC5,
  "darken-2": #1750A5,
  "darken-3": #0E3885,
  "darken-4": #09276E
);
$light: (
  "base": #DBDEE5
);
$dark: (
  "base": #1A051D
);

$grey-color: (
  "lighten-4": #f5f5f5,
  "lighten-3": #eee,
  "lighten-2": #e0e0e0,
  "lighten-1": #bdbdbd,
  "base": #9e9e9e,
  "darken-1": #757575,
  "darken-2": #616161,
  "darken-3": #424242,
  "darken-4": #212121
);

$shades: (
  "black": #000000,
  "white": #ffffff,
  "transparent": transparent
);

$colors: (
  "white": $white-color,
  "black": $black-color,
  "primary": $primary-color,
  "secondary": $secondary-color,
  "success": $success-color,
  "danger": $danger-color,
  "warning": $warning-color,
  "info": $info-color,
  "light": $light,
  "dark": $dark,
  "grey": $grey-color
);

$gradient-colors: (
  gradient-mint: (
    "start-color": #226CC5,
    "end-color": #6CD975
  ),
  gradient-pomegranate: (
    "start-color": #465375,
    "end-color": #F55252
  ),
  gradient-blackberry: (
    "start-color": #303C62,
    "end-color": #B5C3DB
  ),
  gradient-king-yna: (
    "start-color": #7441DB,
    "end-color": #F9877C
  ),
  gradient-ibiza-sunset: (
    "start-color": #B02940,
    "end-color": #FCC173
  ),
  gradient-flickr: (
    "start-color": #F77E17,
    "end-color": #2F8BE6
  ),
  gradient-purple-bliss: (
    "start-color": #562DB7,
    "end-color": #60AFF0
  ),
  gradient-man-of-steel: (
    "start-color": #2F3EA0,
    "end-color": #AE342D
  ),
  gradient-purple-love: (
    "start-color": #7441DB,
    "end-color": #C89CFF
  ),
  gradient-starfall: (
    "start-color": #D23B48,
    "end-color": #2F8BE6
  )
);
