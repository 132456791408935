@import url("../assets/fonts/feather/style.min.css");
@import url("../assets/fonts/simple-line-icons/style.css");
@import url("../assets/fonts/weathericons/css/weather-icons.css");
@import url("../assets/fonts/weathericons/css/weather-icons-wind.css");
@import url("../assets/vendor/pace/themes/black/pace-theme-flash.css");


@import 'bootstrap';
@import 'bootstrap-extended';
@import 'colors';
@import 'components';
@import "themes/layout-dark";
@import "core/menu/horizontal-menu";
@import 'overrides';

/* width % classes from 1 to 100 */
@for $i from 1 through 100 {
  .w-#{$i} {
    width: unquote('#{$i}% !important');
  }
}
/* width px classes from 1 to 100 */
@for $i from 1 through 100 {
  .w-#{$i}px {
    width: unquote('#{$i}px !important');
  }
}
