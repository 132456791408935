.card {
  margin: 15px 0;
  @include card-shadow();

  &[class*="gradient-"] {
    .card-header,
    .card-footer {
      background-color: initial;
    }
  }

  .card-header {
    background-color: green;
    padding-bottom: 0;
    border-bottom: none;
    background-color: transparent;
  }

  .card-subtitle {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .card-bordered {
    border: 1px solid rgba($black, 0.06);
  }

  &.card-transparent {
    background-color: transparent !important;
  }

  .card {
    box-shadow: none;
  }

  .card-img {
    position: relative;

    .card-title {
      color: $white;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding: 24px;
    }

    &.overlap {
      margin-top: -30px;
    }
  }

  .btn-floating {
    display: inline-block;
    color: $white;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    background-color: theme-color("primary");
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    vertical-align: middle;
    font-size: 1rem;
    box-shadow: 0 2px 2px 0 rgba($black, 0.14), 0 1px 5px 0 rgba($black, 0.12),
      0 3px 1px -2px rgba($black, 0.2);

    &.btn {
      margin: 0;
    }

    i {
      width: inherit;
      display: inline-block;
      text-align: center;
      color: $white;
      font-size: 2rem;
      line-height: 40px;
    }

    &.btn-large {
      width: 56px;
      height: 56px;

      &.halfway-fab {
        bottom: -28px;
      }

      i {
        line-height: 56px;
      }
    }

    &.halfway-fab {
      position: absolute;
      right: 24px;
      bottom: -20px;
    }
  }

  .card-reveal {
    padding: 24px;
    position: absolute;
    background-color: $white;
    width: 100%;
    overflow-y: auto;
    left: 0;
    top: 100%;
    height: 100%;
    z-index: 3;
    transition: height 1s ease-in-out;
    display: none;

    .card-title {
      cursor: pointer;
    }
  }

  /* any h* will signal the larger size should render as 24sp */
  .card-title {
    letter-spacing: 0.03rem;
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }

  .card-footer {
    /* assume footer is the set of actions? */
    padding: 1rem 1.5rem;
    background: transparent;
    overflow: hidden;
  }

  &.card-inverse {
    .card-blockquote .blockquote-footer,
    .card-link,
    .card-subtitle,
    .card-text,
    .card-title,
    .card-footer,
    a,
    a:hover,
    label,
    span,
    .form-group:hover,
    div,
    input {
      color: $white;
    }

    .form-group.is-focused {
      .checkbox label,
      label.checkbox-inline,
      .radio label,
      label.radio-inline {
        color: $white;
      }
    }

    hr {
      border-color: $white;
    }

    .checkbox {
      label {
        color: $white;

        .checkbox-decorator {
          .check {
            border-color: rgba($white, 0.54);
          }
        }
      }
    }

    input {
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: $white;
      }
    }
  }
}
