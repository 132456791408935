@import 'core/variables/variables';
@import '~bootstrap/scss/functions';

/* @import '../../assets/scss/variables'; */
@import '~bootstrap/scss/variables';

@import '~bootstrap/scss/bootstrap';

$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  60px: 60px,
  80px: 80px,
  100px: 100px
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

