.progress {
  position: relative;

  .bar {
    z-index: 1;
    position: absolute;
  }

  span {
    position: absolute;
    /* top: 0; */
    z-index: 2;
    color: black;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
}

ul.nav-pills {
  border-radius: 25px;
  background-color: #ffffff;
  /* width: 500px; */
  /* display: flex; */
  display: inline-flex;

  li {
    /* background-color: #fff; */
    .nav-link {
      margin: 8px;
      &.active {
        font-weight: bold;
        background-color: #ea930c;
        color: #ffffff;
        box-shadow: 0 2px 7px rgb(0 0 0 / 28%)
      }
    }
  }
}

/* modal window size bigger xl (1140px) */
.big-modal .modal-dialog {
  /* max-width: 565px; */
  max-width: calc(100vw - 300px);
}

$header-panel-bg: #e99a24;
.header-panel {
  padding: 1.3rem 1.35rem;
  background-color: $header-panel-bg;
  color: #ffffff;
  margin-bottom: 20px;
  &.with-buttons {
    padding: 0.8rem 1.35rem;
  }
  h4 {
    text-transform: uppercase;
    color: #ffffff !important;
    font-size: 1.4rem;
    // margin: 0 0 0 0.75rem;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  h5 {
    color: #ffffff !important;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
}

.header-panel-bg {
  background-color: $header-panel-bg !important;
}

$header-panel2-bg: #ffffff;

.header-panel2 {
  padding: 1.3rem 1.35rem;
  background-color: $header-panel2-bg;
  color: #ffffff;
  margin-bottom: 20px;
  &.with-buttons {
    padding: 0.8rem 1.35rem;
  }
}

.training-past-header-bg {
  background-color: rgb(70, 68, 68) !important;
}

.card {
  .table {
    th {
      border-top: 0;
      padding: 1.2rem 0.75rem;
      &:first-child {
        padding-left: 1.2rem;
      }
    }
    td {
      padding: 0.75rem;
      &:first-child {
        padding-left: 1.2rem;
      }
    }
  }
}

.h-95 {
  height: 95%;
}

.cursor-pointer {
  cursor: pointer;
}