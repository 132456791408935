/* Global Overrides Template Level Only */


/*------- ng-accordion ------- */
.accordion {
  .card {
    box-shadow: -8px 1px 14px 0 rgb(25 42 70 / 11%);
    margin: 0.5rem 0;
    /* background-color: transparent !important; */
    border-radius: 1.5rem !important;

    &:not(:last-of-type) {
      .card-header {
        border-bottom: 1px solid $border-color;
      }
    }

    .card-header {
      padding: 0;

      button {
        padding: 1.5rem;
        width: 100%;
        text-align: start;

        &:hover {
          box-shadow: none;
        }
      }
    }

    .card-body {
      padding-top: 0;
      padding-bottom: 0;
    }

  }
}
 
/* for dark and transparent layouts */
html body.layout-dark {
  .accordion .card {
    box-shadow: none;
  }

  &:not(.layout-transparent) {
    .accordion .card .card-header {
      border-color: $dl-border-color;
    }
  }

  &.layout-transparent {
    .accordion .card .card-header {
      border-color: $tl-border-color;

      button:not([disabled]) {
        color: map-get($primary-color, lighten-4);
      }
    }
  }
}



/*------- horizontal timeline ------- */
.cd-horizontal-timeline .events-content li {
  transform: translateX(0);
}



/*------- time picker ------- */
.ngb-tp-input-container button:hover {
  box-shadow: none;
}


/*------- Chartist Tooltip ------- */
.chartist-tooltip:not(.tooltip-show) {
  padding: 0;

  &:before {
    border: 0;
  }
}
