html {
  font-size: $font-size-root;
  line-height: 1.45rem;
}

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background: rgb(144,198,120);
  // background: linear-gradient(180deg, rgba(144,198,120,0.604079131652661) 0%, rgba(0,212,255,0.604079131652661) 100%);
  background: linear-gradient(90deg, rgba(144,198,120,0.604079131652661) 0%, rgba(0,212,255,0.604079131652661) 100%);
}
.wrapper {
  &.wrapper-full-page {
    height: auto;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.content-wrapper {
  padding: 0 30px;
}

.serif-font {
  font-family: $font-family-sans-serif;
}

a {
  &:focus {
    color: darken(map-get($theme-colors,"primary"), 5%);
    // color: darken(theme-color("primary"), 5%);
    text-decoration: none;
  }

  &.text-info {
    &:hover,
    &:focus {
      color: darken(map-get($theme-colors,"info"), 5%);
      // color: darken(theme-color("info"), 5%);
    }
  }
}

p {
  letter-spacing: 0.3px;
}

sup {
  top: -1em;
  position: relative;
  font-size: 0.8em;
  vertical-align:auto;
}

@media (max-width: 576px) {
  .main-panel {
    .main-content {
      .content-wrapper {
        padding: 0 15px;
      }
    }
  }
}
