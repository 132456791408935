/* basic badge */
.badge{
	letter-spacing: 0.3px;
	background-color: $primary;
	color: $white;
  padding: 0.35rem 0.55rem;

	&.badge-light {
		color: $body-color;
	}

	/* round badge */
	&.badge-round {
		padding: 6px 8px;
	}
}
